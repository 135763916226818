<template>
    <footer class="w-full mt-10">
        <transition name="slide-fade">
            <button v-if="showTop" @click="handleScroll" class="fixed p-2 bg-blue-700 hover:bg-blue-800 bottom-5 right-5 flex items-center justify-center font-black text-white">TOP</button>
        </transition>
        <div class="bg-primary p-5 text-white flex flex-col justify-center sm:items-center">
            <p class="text-white">Sales and Support Questions</p>
            <div class="flex flex-col md:flex-row sm:items-center">
                <a class="text-white sm:text-3xl font-bold hover:text-secondary" href="tel:1.888.535.5776">Call 1.888.535.5776</a>
            </div>
        </div>
        <div class="w-full h-1 bg-secondary"></div>
        <div class="bg-black py-20 px-5 text-white flex flex-col justify-center sm:items-center">
            <address class="text-blue-100 flex flex-col sm:flex-row">
                <div>KellPro, Inc.<span class="mx-4 hidden sm:inline">|</span></div>
                <div><a href="http://maps.google.com/?q=KellPro" rel="noreferrer" target="_blank" class="hover:text-secondary">1729 W. Plato Rd., Duncan, OK 73533</a> <span class="mx-4 hidden sm:inline">|</span></div>
                <div><a href="tel:888.535.5776" class="hover:text-secondary">888.535.5776</a><span class="mx-4 hidden sm:inline">|</span></div>
                <div><a href="mailto:sales@kellpro.com" class="hover:text-secondary">sales@kellpro.com</a></div>
            </address>
            <span class="mt-4 text-sm text-secondary font-bold">{{ year }} © KellPro, Inc.</span>
        </div>
    </footer>
</template>

<script>
    export default {
        name: "Footer",
        data(){
          return {
              showTop: false
          }
        },
        computed: {
            year() { return new Date().getFullYear() }
        },
        methods: {
            handleScroll() {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            },
            scrolling() {
                this.showTop = window.pageYOffset > 500;
            }
        },
        created(){
            window.addEventListener("scroll", this.scrolling)
        }
    };
</script>

<style scoped>
    .slide-fade-enter-active {
        transition: all .3s ease;
    }
    .slide-fade-leave-active {
        transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active below version 2.1.8 */ {
        transform: translateX(10px);
        opacity: 0;
    }
</style>