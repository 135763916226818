<template>
    <div>
        <Navigation />
        <div class="body-wrap">
            <Team id="ourteam"/>
        </div>
    </div>
</template>

<script>
    import Team from "../components/Team";
    import Navigation from "../components/Navigation";

    export default {
        name: "OurTeam",
        components: {Navigation, Team},
        metaInfo: {
            title: 'KellPro, Inc',
        }
    };
</script>
