<template>
    <div>
        <Navigation />
        <div class="body-wrap">
            <Products />
        </div>
    </div>
</template>

<script>
    import Products from "../components/Products";
    import Navigation from "../components/Navigation";

    export default {
        name: "Home",
        components: {Products, Navigation},
        metaInfo: {
            title: 'KellPro, Inc',
        }
    };
</script>
