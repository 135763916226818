<template>
    <div>
        <Navigation />
        <div class="body-wrap">
            <div class="w-full md:w-2/3 px-5 py-10 mx-auto border-2 border-primary rounded-lg shadow-lg text-center relative">
                <button @click="$router.push({ name: 'products', params: {slug: product.slug}})" class="text-xs text-primary absolute top-3 left-4">Back</button>
                <div class="flex flex-col items-center mb-8">
                    <h1 class="text-3xl font-black text-primary -mb-2">{{ pageContent.title }}</h1>
                    <div class="w-1/3 border-b-8 border-secondary"></div>
                </div>
                <img :src="'/assets/'+ product.image +'-horizontal-primary.png'" :alt="product.name" class="mb-10 mx-auto"/>
                <div v-html="pageContent.body"></div>
                <form v-if="!sentStatus" class="w-full md:w-3/5 mt-10 mx-auto mt-5 space-y-3 flex flex-col text-sm" autocomplete="on" ref="form">
                    <input type="text" v-model="form.officeName" name="office" placeholder="Office Name"/>
                    <div class="grid grid-cols-2 gap-4">
                        <input type="text" v-model="form.firstName" name="firstName" placeholder="First Name" required/>
                        <input type="text" v-model="form.lastName" name="lastName" placeholder="Last Name" required/>
                    </div>
                    <div class="grid grid-cols-6 gap-4">
                        <input class="col-span-4" type="text" v-model="form.city" name="city" placeholder="City" required/>
                        <select class="col-span-2" v-model="form.state" name="state" required>
                            <option value="" selected>State</option>
                            <option v-for="(state, abr) in states" :key="abr" :value="abr">{{ state }}</option>
                        </select>
                    </div>
                    <input type="email" v-model="form.email" name="email" placeholder="Email" autocomplete="on" required/>
                    <div v-if="pageContent.additionalForm" class="space-y-3 flex flex-col">
                        <input type="tel" v-model="form.phone" name="phone" placeholder="Phone Number" required/>
                        <div class="py-3 inline-flex justify-between items-center text-xs">
                            <p class="mr-2">How would you like us to contact you:</p>
                            <label class="inline-flex items-center">
                                <input type="radio" value="email" v-model="form.preference" name="contact-preference" required/>
                                <span class="ml-2">Email</span>
                            </label>
                            <label class="inline-flex items-center">
                                <input type="radio" value="phone" v-model="form.preference" name="contact-preference"/>
                                <span class="ml-2">Phone Call</span>
                            </label>
                        </div>
                        <textarea v-model="form.note" name="note" id="" cols="30" rows="10" placeholder="Note"></textarea>
                    </div>
                    <input type="submit" id="submit-hidden" hidden style="display: none"/>
                    <button @click="processForm" class="px-4 py-2 mt-3 border border-primary rounded text-primary font-bold text-sm self-center">{{ pageContent.button }}</button>
                    <p v-if="errors.length" class="bg-red-100 border-2 border-red-300 p-2 text-red-700">
                      <b>We ran into a problem sending your request:</b>
                      <ul>
                        <li v-for="(error, idx) in errors" :key="idx">{{ error }}</li>
                      </ul>
                    </p>
                </form>
                <div v-if="sentStatus" class="bg-green-100 border-2 border-green-300 p-2 mt-5">
                  <p class="text-green-700 font-semibold">Your request has been successfully sent.</p>
                  <p class="text-green-700">We will get back with you shortly.</p>
                  <router-link class="product-navigation-button text-blue-700" :to="{ name: 'home'}">Visit Home</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';
    import product from "../data/products";
    import Navigation from "../components/Navigation";
    import states from "../data/states_hash";
    import { load as recaptchaLoader } from 'recaptcha-v3';

    const RECAPTCHATOKEN = '6Lciz2caAAAAACOE6oWHZS1rtzD64fLNLVcggI1Z';

    export default {
        name: "Inquiry",
        components: {Navigation},
        data(){
          return {
              product: {},
              type: this.$route.name,
              title: '',
              pageContent: {},
              states: states,
              sentStatus: false,
              errors: [],
              form: {
                  'officeName': '',
                  'firstName': '',
                  'lastName': '',
                  'city': '',
                  'state': '',
                  'email': '',
                  'phone': '',
                  'preference': '',
                  'note': '',
                  'requestType': '',
                  'product': ''
              }
          }
        },
        metaInfo(){
          return {
              title: this.pageContent.title
          }
        },
        methods: {
            async processForm(e) {
                e.preventDefault();
                let htmlForm = this.$refs.form;

                if (htmlForm.checkValidity()) {
                  try {
                    recaptchaLoader(RECAPTCHATOKEN, {explicitRenderParameters: {badge: 'bottomleft'}})
                      .then(recaptcha => {
                        recaptcha.execute('submit').then((token) => {
                          fetch(process.env.VUE_APP_EMAIL_END_POINT, {
                            method: 'POST',
                            body: JSON.stringify({token: token, form: {...this.form, product: this.product.name}})
                          })
                            .then(res => res.json())
                            .then(data => {
                              console.log(data)
                              if (data.message === 'OK') {
                                this.sentStatus = true;
                              } else {
                                this.errors.push(data.message);
                              }
                            })
                            .catch(err => {
                              if (err.toString() === 'TypeError: Failed to fetch') {
                                this.errors.push('Please contact support at support@kellpro.com');
                              } else {
                                this.errors.push(err.toString());
                              }
                            })
                        });
                      })
                  } catch (e) {
                    this.errors.push('Please contact support at support@kellpro.com');
                  }
                } else {
                  htmlForm.querySelector('#submit-hidden').click();
                }
            }
        },
        created(){
            let foundProduct = _.find(product, ['slug', this.$route.params.slug]);

            if(!foundProduct) {
                this.$router.push('/');
            } else {
                this.product = foundProduct;
            }

            switch(this.$route.name) {
                case 'askconsultant':
                    this.pageContent = {
                        title: 'Speak with a Consultant',
                        button: 'Send Request',
                        body: `<p>Thank you for your interest in the ${foundProduct.name}.</p>
                               <br/>
                               <p>We would love to evaluate your software needs to see if the ${foundProduct.name} is right for you. Please fill out this form and one of our consultants will contact you.</p>`,
                        additionalForm: true,
                    };
                    this.form.requestType = 'ask for consultant';
                    break;
                case 'getbrochure':
                    this.pageContent = {
                        title: 'Request Product Brochure',
                        button: 'Download Brochure',
                        body: `<p>Thank you for your interest in the ${foundProduct.name}.</p>`
                    };
                    this.form.requestType = 'get product brochure';
                    break;
                default:
                    break;
            }

        }
    };
</script>
