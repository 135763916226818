<template>
    <div class="w-full md:w-2/3 p-8 md:p-16 mx-auto border border-primary rounded-lg shadow-lg text-primary">
        <section>
            <div class="flex flex-col items-center mb-8">
                <h1 class="text-4xl font-bold highlighted -mb-2">About Us</h1>
                <div class="w-1/3 border-b-8 border-secondary"></div>
            </div>
            <div class="text-center">
                <p class="mb-8">At KellPro, we are passionate about technology and serving our customers! Over the last 30 years, we have grown by discovering the needs of our customers and providing the technology solutions and support they require for their daily work.</p>
                <p class="mb-8">We are thrilled to support over 3,000 office users and hundreds of thousands of public users on the
                    systems our customers have requested we design.</p>
                <p>As we go about our day, you, the customer, are constantly on our minds as we continue to innovate to
                    serve you better.</p>
            </div>
        </section>
        <section class="mt-6 text-center font-bold">
            <h2 class="mb-4 text-gray-500 text-l uppercase">Our Corporate Values</h2>
            <div class="flex">
                <div
                    v-for="(value, index) in values"
                    :key="index"
                    class="w-1/3"
                    :class="[(index+1 !== values.length)? ' border-r-2': '']"
                >
                    <span v-html="value"></span>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: "AboutUs",
        data(){
            return {
                values: ['Honoring <br> God', 'Serving <br> Customers', 'Investing <br> Into Team Members']
            }
        }
    };
</script>
