import Vue from 'vue';
import App from './App.vue';
import router from './router';
import './style/index.css';
import VueMeta from 'vue-meta';

Vue.config.productionTip = false;

new Vue({
  el: '#app',
  render: h => h(App),
  router
});

Vue.use(VueMeta, { refreshOnceOnNavigation: true });
