import Vue from "vue";
import Router from "vue-router";
import Products from "./pages/Products";
import Product from "./pages/Product";
import Home from "./pages/Home";
import About from "./pages/About";
import Team from "./pages/OurTeam";
import Support from "./pages/Support";
import Error from "./pages/Error";
import Inquiry from "./pages/Inquiry";

Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [
        {
            path: "/",
            name: "home",
            component: Home
        },
        {
            path: '/products/:slug',
            component: Products,
            children: [
                {
                    path: '',
                    name: 'products',
                    component: Product
                },
                {
                    path: 'request-consultant',
                    name: 'askconsultant',
                    component: Inquiry,
                },
                {
                    path: 'request-brochure',
                    name: 'getbrochure',
                    component: Inquiry,
                },
                {
                    path: 'buy-license',
                    name: 'buylicense',
                    component: Inquiry,
                },
            ]
        },
        {
            path: '/about',
            name: 'about',
            component: About,
        },
        {
            path: '/team',
            name: 'team',
            component: Team,
        },
        {
            path: '/support',
            name: 'support',
            component: Support,
        },
        {
            path: '*',
            name: 'Error',
            component: Error
        }
    ],
    scrollBehavior (to, from, savedPosition) {
        if(to.hash ) {
            return {
                selector: to.hash,
                behavior: 'smooth',
                offset: {x:0, y: 80}
            }
        }

        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
})