<template>
    <div>
        <router-view></router-view>
        <div class="relative" style="height: 640px">
            <Navigation transparent class="absolute w-full"/>
            <div class="absolute bottom-0 w-full">
                <div class="max-w-screen-xl mx-auto px-5 pb-10">
                    <img :src="'/assets/'+ product.image +'-horizontal-white.png'" alt="" class="mb-10"/>
                    <p v-if="windowWidth < 500" class="mb-10 text-white">{{ product.excerpt }}</p>
                    <p v-else class="mb-10 text-white">{{ product.description }}</p>
                    <div class="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
                        <router-link
                            class="product-navigation-button"
                            :to="{ name: 'askconsultant', params: { slug: product.slug } }"
                        >Speak with a Consultant</router-link>
                        <a v-for="(asset, index) in product.assets" :href="asset.path" :key="index" target="_blank" class="product-navigation-button">{{ asset.type }}</a>
                        <button @click="buyProductAction" v-if="product.pricing && product.pricing.enabled" class="product-navigation-button">Buy License</button>
                    </div>
                </div>
            </div>
            <div class="h-full" :style="'background: url(\'/assets/'+ product.image +'-hero.png\') center center / cover no-repeat;'"></div>
        </div>
        <div class="bg-yellow-400 sticky top-0">
            <div class="max-w-screen-xl mx-auto px-5 py-2">
                <nav>
                    <ul class="inline-flex sm:space-x-4">
                        <li><router-link class="product-navigation" :to="{hash: '#about'}">About</router-link></li>
                        <li><router-link class="product-navigation" :to="{hash: '#features'}">Features</router-link></li>
                        <li><router-link class="product-navigation" :to="{hash: '#review'}">Customer Review</router-link></li>
                        <li><router-link :to="{ name: 'support' }" class="product-navigation">Support</router-link></li>
                    </ul>
                </nav>
            </div>

        </div>
        <div class="body-wrap pt-0">
            <section id="about" class="my-20 lg:grid grid-cols-4 gap-4 space-y-5">
                <div class="flex items-center justify-center">
                    <img :src="'/assets/' + product.image + '.png'" :alt="product.name"/>
                </div>
                <p class="col-span-3" v-html="product.full_description"></p>
            </section>
            <hr class="border-secondary"/>
            <section id="features" class="my-20 lg:grid grid-cols-4 gap-4">
                <h1 class="text-3xl mb-5 text-primary">Features and Benefits</h1>
                <ul class="col-span-3 list-disc list-inside leading-9">
                    <li v-for="(item, index) in product.features" :key="index" class="text-primary">{{ item }}</li>
                </ul>
            </section>
            <hr class="border-secondary"/>
            <section id="review" class="my-20 lg:grid grid-cols-4 gap-4">
                <h1 class="text-3xl mb-5 text-primary">What Our Customers Say About Our Product</h1>
                <div class="col-span-3 space-y-5">
                    <!--
                    <img
                        v-if="product.review.imagePath"
                        :src="'/assets/' + product.review.imagePath"
                        :alt="'Image of ' + product.review.customer"
                    />
                    -->
                    <p v-html="product.review.content"></p>
                    <div>
                        <p
                            v-for="(part, index) in product.review.customer.split(',')"
                            :key="index"
                            class="italic"
                            :class="[ index > 0? '-mt-2' : 'uppercase font-bold' ]">
                            {{ part }}
                        </p>
                    </div>
                </div>
            </section>

            <div class="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
                <router-link
                    class="product-navigation-button"
                    :to="{ name: 'askconsultant', params: { slug: product.slug } }"
                >Speak with a Consultant</router-link>
                <a v-for="(asset, index) in product.assets" :href="asset.path" :key="index" target="_blank" class="product-navigation-button">{{ asset.type }}</a>
                <button @click="buyProductAction" v-if="product.pricing && product.pricing.enabled" class="product-navigation-button">Buy License</button>
            </div>
        </div>
    </div>
</template>

<script>
    import Navigation from "../components/Navigation";
    import _ from "lodash";
    import product from "../data/products";

    export default {
        name: "ProductDetail",
        components: {Navigation},
        data() {
            return {
                product: {},
                windowWidth: ''
            }
        },
        metaInfo(){
            return {
                title: this.product.name,
                meta: [
                    {
                        vmid: 'description',
                        name: 'description',
                        content: this.product.excerpt
                    }
                ]
            }
        },
        methods: {
            buyProductAction(){
                alert('TEST -- BUY LICENSE PAGE')
            },
            handleResize() {
                this.windowWidth = window.innerWidth;
            },
        },
        created(){
            window.addEventListener('resize', this.handleResize);
            this.handleResize();

            let e = _.find(product, ['slug', this.$route.params.slug]);
            if(!e) {
                this.$router.push('/');
            } else {
                this.product = e;
            }
        }
    };
</script>

<style scoped>
    .product-navigation {
        @apply hover:bg-yellow-600 hover:text-white py-1 px-3 rounded-md font-bold text-yellow-900 text-sm sm:text-base;
    }
    .product-navigation-button {
        @apply px-7 py-2 bg-blue-600 rounded-md text-white font-bold text-sm text-center;
    }
</style>