<template>
    <div>
        <div v-for="(group, index) in productsGrouped" :key="index" class="mb-5">
            <h1 class="text-4xl font-bold text-center text-primary">{{ group.name }}</h1>
            <div class="">
                <!-- chunk is a row -->
                <div
                    v-for="(chunk, index) in group.products"
                    :key="index"
                    class=""
                >
                    <div class="flex justify-center py-2">
                        <div
                            v-for="(product, productIndex) in chunk"
                            :key="product.id"
                            class="text-center flex w-1/2"
                        >
                            <div class="w-full p-10 product_container relative" @click="click(product)">
                                <div :style="{height: product.mainIcon.height + 'px'}" class="image-container">
                                    <img
                                        :src="'/assets/' + product.image + '-colored.png'"
                                        :alt="product.name + '\'s Symbol'"
                                        :width="product.mainIcon.width"
                                        :height="product.mainIcon.height"
                                        style="display: inline;"
                                    />
                                </div>
                                <p>
                                    {{ product.excerpt }}
                                </p>
                            </div>
                            <!-- the last item in the product shouldn't have border -->
                            <div v-if="productIndex + 1 !== chunk.length" class="h-2/3 self-center border-r-4 border-gray-100"></div>
                        </div>
                    </div>
                    <!-- the last item in the row shouldn't have bottom border -->
                    <div v-if="index + 1 !== group.products.length" class="w-full border-b-4 border-gray-100"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import products from '../data/products'
    import _ from 'lodash'

    export default {
        name: "Products",
        data() {
            return {
                products: products,
                productsGrouped: [],
                window: {
                    width: 0,
                    chunk: false
                }
            }
        },
        computed: {

        },
        methods: {
            click(product) {
                if(product.link) {
                    window.open(product.link, '_blank');
                } else {
                    this.$router.push({ name: `products`, params: {slug: product.slug}});
                }
            },
            handleResize() {
                this.window.width = window.innerWidth;
                if (window.innerWidth < 745) {
                    this.productsByGroup(2);
                    this.window.chunk = true;
                } else {
                    this.productsByGroup(2);
                    this.window.chunk = false;
                }
            },
            productsByGroup(chunkBy = 2) {
                const products = _.orderBy(this.products, ['order'], ['asc']);
                const softwareProducts = products.filter(product => product.category === 1 && product.enabled);
                const publicRecordsProducts = products.filter(product => product.category === 2 && product.enabled);
                const managedProducts = products.filter(product => product.category === 3 && product.enabled);
                this.productsGrouped = [
                    {name: 'Software Solutions', products: _.chunk(softwareProducts, chunkBy)},
                    {name: 'Public Records Solutions', products: _.chunk(publicRecordsProducts, chunkBy)},
                    {name: 'Managed Solutions', products: _.chunk(managedProducts, chunkBy)}
                ];
            }
        },
        created(){
            window.addEventListener('resize', this.handleResize);
            this.handleResize();
        }
    };
</script>

<style>
.product_container {
    cursor: pointer;
    transition: .2s all ease-in-out;
}

.product_container:hover {
    background: rgb(239, 239, 239);
}

.image-container {
    margin-bottom: 20px;
}
</style>
