<template>
  <div id="app">
    <router-view />
    <Footer />
  </div>
</template>

<script>
  import Footer from "./components/Footer";

  export default {
  name: 'App',
  components: {
    Footer
  },
  metaInfo: {
    title: 'KellPro, Inc',
    titleTemplate: '%s | KellPro - Database and Accounting Systems',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'Easy to use database and accounting systems for small business and county offices.'
      }
    ]
  }
}
</script>

<style>
  p {
    @apply text-primary;
  }
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .body-wrap {
    @apply max-w-screen-xl mx-auto px-5 py-16;
  }
</style>
