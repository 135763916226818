<template>
    <div class="text-primary">
        <h1 class="text-4xl text-center font-bold mb-4">Meet Our Team</h1>
        <nav class="text-center ">
            <ul class="flex flex-wrap justify-center">
                <li v-for="(team, index) in teams" :key="index" class="flex flex-wrap">
                    <button
                        class="mx-1 px-3 py-1 hover:bg-secondary hover:text-yellow-900 text-sm uppercase font-bold"
                        :class="[selectedTeam.name === team.name? 'bg-secondary text-yellow-900': '']"
                        @click="getTeamData(team.id)">
                        {{ team.name.replace('Team', '') }}
                    </button>
                    <div
                        v-if="index + 1 !== teams.length"
                        class="h-1/2 border-r-2 border-primary self-center"></div>
                </li>
            </ul>
        </nav>
        <div class="mt-10 flex flex-col md:flex-row">
            <div class="w-full md:w-1/3">
                <div class="flex flex-col mb-8">
                    <h1
                        class="text-2xl font-bold uppercase text-center md:text-left -mb-2">
                        {{ selectedTeam.name }}
                    </h1>
                    <div class="border-b-8 border-secondary"></div>
                </div>

                <p class="my-2">{{ selectedTeam.description }}</p>
                <p class="my-2 hidden md:block">{{ selectedTeam.full_description }}</p>
            </div>
            <div class="w-full md:w-2/3 grid grid-cols-3 md:grid-cols-4">
                <div v-for="(member, index) in selectedTeam.members" :key="index" class="m-2 p-3 text-center">
                    <img :src="'/assets/members/' + member.image +'.jpg'" alt="" class="rounded-full mx-auto mb-3" width="100" height="100"/>
                    <p class="text-sm">{{ member.name }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import teamMembers from '../data/team_member'
    import teams from '../data/teams'
    import _ from 'lodash'

    export default {
        name: "Team",
        data() {
            return {
                teamMembers:  _.orderBy(_.filter(teamMembers, 'active'), ['name', 'asc'], ['order', 'asc']),
                teams: _.filter(teams, 'enabled'),
                selectedTeam: teams[0]
            }
        },
        methods: {
            getTeamData(id) {
                let teamMembers = _.filter(this.teamMembers, ['team_id', id]);
                this.selectedTeam = _.find(this.teams, ['id', id]);

                if(id === 7) {
                    // leadership team, sort by order
                    teamMembers = _.orderBy(_.filter(this.teamMembers, ['team_id', id]), ['order', 'asc']);
                }
                this.selectedTeam['members'] = teamMembers;
            }
        },
        created(){
            this.selectedTeam['members'] = _.filter(this.teamMembers, ['team_id', this.teams[0].id]);
        }
    };
</script>

<style scoped>

</style>