<template>
    <nav>
        <div class="h-8" :class="[transparent? 'bg-gray-100 bg-opacity-20' : 'bg-gray-100']">
            <div
                class="max-w-screen-xl mx-auto px-5 h-full flex items-center justify-end uppercase text-xs font-bold"
                :class="{ 'text-white': transparent }"
            >
                <a href="https://forum.kellpro.com">Visit Forum</a>
                <span class="mx-4">|</span>
                <a href="tel:8885355776">Call 888.535.5776</a>
            </div>
        </div>
        <div v-if="transparent" class="h-2.5 bg-secondary"></div>
        <div class="py-9" :class="[!transparent? 'bg-primary' : '', navigation? 'bg-primary' : '']">
            <div class="max-w-screen-xl mx-auto px-5 flex flex-row items-center justify-between">
                <router-link to="/">
                    <img src="../../public/assets/KellPro-Logo-flat-white.png" alt="KellPro" width="194" height="35"/>
                </router-link>
                <ul class="hidden md:flex text-white font-bold uppercase space-x-4">
                    <li>
                        <router-link to="/">Home</router-link>
                    </li>
                    <li>
                        <router-link to="/about">About Us</router-link>
                    </li>
                    <li>
                        <router-link to="/team">Our Team</router-link>
                    </li>
                    <li>
                        <router-link to="/support">Support</router-link>
                    </li>
                </ul>
                <button
                    class="md:hidden px-5 text-white font-bold uppercase"
                    @click="openNav"
                >menu</button>
            </div>
        </div>
        <div v-if="!transparent" class="h-2.5 bg-secondary"></div>
        <transition name="slide-fade">
            <div v-if="navigation" class="absolute w-full bg-blue-900 z-10 flex flex-col space-y-5" style="height: 100vh">
                <router-link v-on:click.native="openNav" to="/" class="mobile-navigation">Home</router-link>
                <router-link v-on:click.native="openNav" to="/about" class="mobile-navigation">About Us</router-link>
                <router-link v-on:click.native="openNav" to="/team" class="mobile-navigation">Our Team</router-link>
                <router-link to="/support" class="mobile-navigation">Support</router-link>
            </div>
        </transition>
    </nav>
</template>
<script>
    export default {
        name: 'Navigation',
        props: {
            transparent: Boolean
        },
        data(){
          return {
              navigation: false
          }
        },
        methods:{
            openNav() {
                this.navigation = !this.navigation;
                console.log('clicked')
            }
        }
    };
</script>
<style>
    .mobile-navigation {
        @apply w-full py-2 hover:bg-primary text-center text-2xl uppercase font-bold text-secondary;
    }
    .slide-fade-enter-active {
        transition: all .3s ease;
    }
    .slide-fade-leave-active {
        transition: all .3s ease;
    }
    .slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active below version 2.1.8 */ {
        transform: translateY(-10px);
        opacity: 0;
    }
</style>
