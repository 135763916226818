<template>
    <div>Non existent</div>
</template>

<script>
    export default {
        name: "Error",
        created(){
            this.$router.push('/')
        }
    };
</script>
