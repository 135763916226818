<template>
    <div>
        <Navigation />
        <div class="body-wrap">
            <AboutUs id="aboutus" class="my-24"/>
        </div>
    </div>
</template>

<script>
    import AboutUs from "../components/AboutUs";
    import Navigation from "../components/Navigation";

    export default {
        name: "About",
        components: {AboutUs, Navigation},
        metaInfo: {
            title: 'KellPro, Inc',
        }
    };
</script>
